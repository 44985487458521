"use client";

import { useQuery } from "@tanstack/react-query";
import { TemplateTile } from "../../shared/templates/template-tile";
import { Description, GradientText, indigoPurple, tealIndigo, Title } from "./components";
import { InfiniteMovingCards } from "./infinite-moving-cards";
import { apiClient } from "@/server/init";

export function PowerfulTemplates() {
  const { data } = useQuery({
    queryKey: ["landing-page-templates"],
    queryFn: async () => {
      const response = await apiClient.templates.getMany({
        query: { limit: 10, offset: 0, filter: "VIDEO_TO_VIDEO" },
      });

      return response.body.items;
    },
  });

  const items = (data ?? []).map((template) => {
    return (
      <a key={template.id} className="inline-block max-w-40" href="/create">
        <TemplateTile {...template} ignoreVideoAspectRatio={true} />
      </a>
    );
  });

  return (
    <div className="flex flex-col items-center">
      <div className="flex max-w-full flex-col items-center gap-8">
        <Title>
          Use <GradientText gradient={tealIndigo}>Templates</GradientText> to Quickly
          <br /> Create <GradientText gradient={indigoPurple}>Effortlessly</GradientText>
        </Title>

        <Description>
          Unlock thousands of templates and effects to meet your audience where they are.
        </Description>

        <InfiniteMovingCards items={items} direction="right" speed="normal" />

        <a className="btn btn-primary rounded-full text-white" href="/templates">
          Explore More Templates
        </a>
      </div>
    </div>
  );
}
